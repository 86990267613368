<template>
  <div class="text-center w-100">
    <TTBtn
      fab
      depressed
      color="white"
      elevation="1"
      large
      data-test="aside-right-level-add"
      class="mb-2 v-btn--aside"
      :loading="loading"
      @click="downloadReport"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-arrow-to-bottom
      </VIcon>
    </TTBtn>
    <div class="tt-text-caption tt-black--text text--lighten-2">
      Выгрузить<br>
      отчет
    </div>
  </div>
</template>

<script>
import { downloadCSVLink } from '@/utils/CSV';
import { pageLocationToken } from '@/services';
import { API_REPORT_ALL_TASKS } from '@/services/backend/snami/constants';

export default {
  name: 'AppAnalyticsAsideRight',
  data: () => ({
    loading: false,
  }),
  methods: {
    async downloadReport() {
      this.loading = true;
      try {
        const locationId = pageLocationToken.get();
        const link = API_REPORT_ALL_TASKS(locationId);
        await downloadCSVLink(link);
      } catch (e) {
        // TODO: добавить обработчик ошибки
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 3000);
        this.$root.$emit('showNotifier', {
          title: 'Подготовка файла',
          msg: 'Скачивание скоро начнется',
          timeout: 3000,
          type: 'info',
        });
      }
    },
  },
};
</script>
